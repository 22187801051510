import { NgModule, APP_INITIALIZER } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QueueService } from "./queue.service";
import {
  RouterEvent,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterModule,
  Router
} from "@angular/router";

@NgModule({
  declarations: [],
  imports: [CommonModule, RouterModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: QueueService) =>
        function() {
          return ds.init();
        },
      deps: [QueueService],
      multi: true
    }
  ]
})
export class QueueModule {}
