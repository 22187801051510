import * as tslib_1 from "tslib";
import * as fromShop from '../actions/shop.action';
export var initialState = {
    shop: null,
    order: null,
    orderSynced: false,
    orderSyncing: false,
    cart: {},
    loaded: false,
    loading: false,
    language: null,
    paymentMethod: null
    // languages: [
    //   {
    //     iso_alpha_2: 'en',
    //     icon: '',
    //     language: 'English'
    //   },
    //   {
    //     iso_alpha_2: 'nl',
    //     icon: '',
    //     language: 'Nederlands'
    //   }
    // ]
};
export function reducer(state, action) {
    if (state === void 0) { state = initialState; }
    var _a;
    switch (action.type) {
        case fromShop.LOAD_SHOP: {
            return tslib_1.__assign({}, state, { order: null, loading: true, loaded: false, shop: null });
        }
        case fromShop.LOADED_SHOP: {
            var shop_1 = action.payload;
            return tslib_1.__assign({}, state, { loading: false, loaded: true, shop: shop_1, language: state.language
                    ? state.language
                    : shop_1.languages.find(function (l) { return l.iso_alpha_2 === shop_1.default_language; }) });
        }
        case fromShop.UNLOAD_SHOP: {
            return tslib_1.__assign({}, state, { order: null, loading: false, loaded: false, shop: null });
        }
        case fromShop.UNLOAD_ALL: {
            return tslib_1.__assign({}, state, { order: null, loading: false, loaded: false, orderSynced: false, orderSyncing: false, paymentMethod: null, shop: null });
        }
        case fromShop.LOADED_ORDER: {
            var order_1 = action.payload;
            var cart = order_1.tickets.reduce(function (prev, next) {
                var _a;
                return tslib_1.__assign({}, prev, (_a = {}, _a[next.ticket_type_uuid] = order_1.tickets.filter(function (t) { return t.ticket_type_uuid === next.ticket_type_uuid; }).length, _a));
            }, {});
            return tslib_1.__assign({}, state, { order: tslib_1.__assign({}, state.order, order_1), cart: cart, orderSynced: true });
        }
        case fromShop.ERROR_UPDATING_ORDER: {
            var order_2 = state.order;
            var cart = order_2.tickets.reduce(function (prev, next) {
                var _a;
                return tslib_1.__assign({}, prev, (_a = {}, _a[next.ticket_type_uuid] = order_2.tickets.filter(function (t) { return t.ticket_type_uuid === next.ticket_type_uuid; }).length, _a));
            }, {});
            return tslib_1.__assign({}, state, { cart: tslib_1.__assign({}, cart), orderSynced: true });
        }
        case fromShop.ERROR_LOADING_ORDER: {
            return tslib_1.__assign({}, state, { orderSynced: true });
        }
        case fromShop.CART_TICKET_UPDATED: {
            var ticketType = action.payload.ticketType;
            var amount = action.payload.amount;
            return tslib_1.__assign({}, state, { orderSynced: false, orderSyncing: false, cart: tslib_1.__assign({}, state.cart, (_a = {}, _a[ticketType.uuid] = amount, _a)) });
        }
        case fromShop.SELECT_LANGUAGE: {
            return tslib_1.__assign({}, state, { language: action.payload });
        }
        case fromShop.TICKET_PRODUCTS_UPDATED: {
            var payload_1 = action.payload;
            return tslib_1.__assign({}, state, { order: tslib_1.__assign({}, state.order, { tickets: state.order.tickets.map(function (ticket) {
                        if (ticket.uuid === payload_1.uuid) {
                            return payload_1;
                        }
                        return ticket;
                    }) }) });
        }
        case fromShop.COUPONS_UPDATED: {
            var payload = action.payload;
            return tslib_1.__assign({}, state, { order: tslib_1.__assign({}, state.order, { discount: payload.discount, service_fee_total: payload.service_fee_total, ticket_total: payload.ticket_total, total: payload.total, coupons: payload.coupons }) });
        }
        case fromShop.DETAILS_UPDATED: {
            var payload = action.payload;
            return tslib_1.__assign({}, state, { order: payload });
        }
        case fromShop.SET_PAYMENT_METHOD: {
            var payload = action.payload;
            return tslib_1.__assign({}, state, { paymentMethod: payload });
        }
    }
    return state;
}
export var getShopState = function (state) { return state.shop; };
export var getCartState = function (state) { return state.cart; };
export var getOrderState = function (state) { return state.order; };
export var getOrderSynchedState = function (state) { return state.orderSynced; };
export var getLoadedState = function (state) { return state.loaded; };
export var getLoadingState = function (state) { return state.loading; };
export var getLanguageState = function (state) { return state.language; };
export var getPaymentMethodState = function (state) { return state.paymentMethod; };
