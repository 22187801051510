import * as fromShop from '../actions/shop.action';

import { Shop, Order, Cart, Language, PaymentMethod } from '@shared/models';

export interface ShopState {
  shop: Shop;
  order: Order;
  orderSynced: boolean;
  orderSyncing: boolean;
  cart: Cart;
  loaded: boolean;
  loading: boolean;
  language: Language;
  paymentMethod: PaymentMethod;
  // languages: Language[];
}

export const initialState: ShopState = {
  shop: null,
  order: null,
  orderSynced: false,
  orderSyncing: false,
  cart: {},
  loaded: false,
  loading: false,
  language: null,
  paymentMethod: null
  // languages: [
  //   {
  //     iso_alpha_2: 'en',
  //     icon: '',
  //     language: 'English'
  //   },
  //   {
  //     iso_alpha_2: 'nl',
  //     icon: '',
  //     language: 'Nederlands'
  //   }
  // ]
};

export function reducer(
  state = initialState,
  action: fromShop.ShopAction
): ShopState {
  switch (action.type) {
    case fromShop.LOAD_SHOP: {
      return {
        ...state,
        order: null,
        loading: true,
        loaded: false,
        shop: null
      };
    }
    case fromShop.LOADED_SHOP: {
      const shop: Shop = action.payload;
      return {
        ...state,
        loading: false,
        loaded: true,
        shop,
        language: state.language
          ? state.language
          : shop.languages.find(l => l.iso_alpha_2 === shop.default_language)
      };
    }
    case fromShop.UNLOAD_SHOP: {
      return {
        ...state,
        order: null,
        loading: false,
        loaded: false,
        shop: null
      };
    }
    case fromShop.UNLOAD_ALL: {
      return {
        ...state,
        order: null,
        loading: false,
        loaded: false,
        orderSynced: false,
        orderSyncing: false,
        paymentMethod: null,
        shop: null
      };
    }
    case fromShop.LOADED_ORDER: {
      const order = action.payload;
      const cart = order.tickets.reduce((prev, next) => {
        return {
          ...prev,
          [next.ticket_type_uuid]: order.tickets.filter(
            t => t.ticket_type_uuid === next.ticket_type_uuid
          ).length
        };
      }, {});
      return {
        ...state,
        order: { ...state.order, ...order },
        cart,
        orderSynced: true
      };
    }
    case fromShop.ERROR_UPDATING_ORDER: {
      const order = state.order;
      const cart = order.tickets.reduce((prev, next) => {
        return {
          ...prev,
          [next.ticket_type_uuid]: order.tickets.filter(
            t => t.ticket_type_uuid === next.ticket_type_uuid
          ).length
        };
      }, {});
      return {
        ...state,
        cart: { ...cart },
        orderSynced: true
      };
    }
    case fromShop.ERROR_LOADING_ORDER: {
      return { ...state, orderSynced: true };
    }
    case fromShop.CART_TICKET_UPDATED: {
      const ticketType = action.payload.ticketType;
      const amount = action.payload.amount;
      return {
        ...state,
        orderSynced: false,
        orderSyncing: false,
        cart: {
          ...state.cart,
          [ticketType.uuid]: amount
        }
      };
    }
    case fromShop.SELECT_LANGUAGE: {
      return {
        ...state,
        language: action.payload
      };
    }
    case fromShop.TICKET_PRODUCTS_UPDATED: {
      const payload = action.payload;
      return {
        ...state,
        order: {
          ...state.order,
          tickets: state.order.tickets.map(ticket => {
            if (ticket.uuid === payload.uuid) {
              return payload;
            }
            return ticket;
          })
        }
        // language: action.payload
      };
    }
    case fromShop.COUPONS_UPDATED: {
      const payload = action.payload;
      return {
        ...state,
        order: {
          ...state.order,
          discount: payload.discount,
          service_fee_total: payload.service_fee_total,
          ticket_total: payload.ticket_total,
          total: payload.total,
          coupons: payload.coupons
        }
      };
    }
    case fromShop.DETAILS_UPDATED: {
      const payload = action.payload;
      return {
        ...state,
        order: payload
      };
    }
    case fromShop.SET_PAYMENT_METHOD: {
      const payload = action.payload;
      return {
        ...state,
        paymentMethod: payload
      };
    }
  }

  return state;
}

export const getShopState = (state: ShopState) => state.shop;
export const getCartState = (state: ShopState) => state.cart;
export const getOrderState = (state: ShopState) => state.order;
export const getOrderSynchedState = (state: ShopState) => state.orderSynced;
export const getLoadedState = (state: ShopState) => state.loaded;
export const getLoadingState = (state: ShopState) => state.loading;
export const getLanguageState = (state: ShopState) => state.language;
export const getPaymentMethodState = (state: ShopState) => state.paymentMethod;
