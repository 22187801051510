import { Action } from '@ngrx/store';
import {
  Shop,
  Order,
  TicketType,
  Language,
  CouponResonse,
  PaymentMethod
} from '@shared/models';

export const UNLOAD_SHOP = '[Shop] Unload';
export const LOAD_SHOP = '[Shop] Load';
export const LOADED_SHOP = '[Shop] Loaded';
export const LOADED_ORDER = '[Shop] Loaded Order';
export const ERROR_UPDATING_ORDER = '[Shop] Error Updating Order';
export const ERROR_LOADING_ORDER = '[Shop] Error Loading Order';
export const CART_TICKET_UPDATED = '[Shop] Cart Ticket Updated';
export const SELECT_LANGUAGE = '[Shop] Select Language';
export const TICKET_PRODUCTS_UPDATED = '[Shop] Ticket Products Updated';
export const COUPONS_UPDATED = '[Shop] Coupons Updated';
export const DETAILS_UPDATED = '[Shop] Details Updated';
export const SET_PAYMENT_METHOD = '[Shop] Set Payment Method';
export const UNLOAD_ALL = '[Shop] Unload All';

export class UnloadShop implements Action {
  readonly type = UNLOAD_SHOP;
  constructor() {}
}

export class LoadedShop implements Action {
  readonly type = LOADED_SHOP;
  constructor(public payload: Shop) {}
}

export class LoadShop implements Action {
  readonly type = LOAD_SHOP;
  constructor(public payload: string) {}
}

export class ErrorUpdatingOrder implements Action {
  readonly type = ERROR_UPDATING_ORDER;
  constructor() {}
}

export class LoadedOrder implements Action {
  readonly type = LOADED_ORDER;
  constructor(public payload: Order) {}
}

export class ErrorLoadingOrder implements Action {
  readonly type = ERROR_LOADING_ORDER;
  constructor() {}
}

export class CartTicketUpdated implements Action {
  readonly type = CART_TICKET_UPDATED;
  constructor(public payload: { ticketType: TicketType; amount: number }) {}
}

export class SelectLanguage implements Action {
  readonly type = SELECT_LANGUAGE;
  constructor(public payload: Language) {}
}

export class TicketProductsUpdated implements Action {
  readonly type = TICKET_PRODUCTS_UPDATED;
  constructor(public payload: any) {}
}

export class CouponsUpdated implements Action {
  readonly type = COUPONS_UPDATED;
  constructor(public payload: CouponResonse) {}
}

export class DetailsUpdated implements Action {
  readonly type = DETAILS_UPDATED;
  constructor(public payload: Order) {}
}

export class SetPaymentMethod implements Action {
  readonly type = SET_PAYMENT_METHOD;
  constructor(public payload: PaymentMethod) {}
}

export class UnloadAll implements Action {
  readonly type = UNLOAD_ALL;
  constructor() {}
}

export type ShopAction =
  | LoadShop
  | LoadedShop
  | UnloadShop
  | LoadedOrder
  | ErrorUpdatingOrder
  | ErrorLoadingOrder
  | CartTicketUpdated
  | SelectLanguage
  | TicketProductsUpdated
  | CouponsUpdated
  | DetailsUpdated
  | SetPaymentMethod
  | UnloadAll;
