import { Injector } from '@angular/core';
import { uuid } from 'uuidv4';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var QueueService = /** @class */ (function () {
    function QueueService(http, injector // private activatedRoute: ActivatedRoute
    ) {
        this.http = http;
        this.injector = injector;
        this.uuid = '';
    }
    QueueService.prototype.init = function () {
        var _this = this;
        var idFromUrl = this.getUrlParameter('queue_id');
        if (idFromUrl !== null) {
            localStorage.setItem('bitscan_queue_uuid', idFromUrl);
        }
        return new Promise(function (resolve, reject) {
            // return resolve();
            if (window.location.href.indexOf('bk-beats') === -1 || window.location.href.indexOf('payment') > -1) {
                return resolve();
            }
            if (localStorage.getItem('bitscan_queue_uuid')) {
                _this.uuid = localStorage.getItem('bitscan_queue_uuid');
            }
            else {
                _this.uuid = uuid();
                localStorage.setItem('bitscan_queue_uuid', _this.uuid);
            }
            _this.http
                .get(environment.queue_url + ("/" + environment.queue_key + "/guest/" + _this.uuid), {
            // params: {
            //   key: environment.queue_key,
            //   url: window.location.href,
            //   id: this.uuid,
            // }
            })
                .subscribe(function (res) {
                if (res.data.allowed_access) {
                    return resolve();
                }
                else {
                    var url = new URL(res.data.queue_url);
                    var redirectUrl = new URL(window.location.href);
                    redirectUrl.searchParams.append('queue_id', _this.uuid);
                    url.searchParams.append('redirect_back', redirectUrl.toString());
                    console.log(url.toString());
                    // console.log(res.data.queue_url + `?redirect_back=${window.location.href}&queue_id=${this.uuid}`);
                    window.location.href = res.data.queue_url + ("?redirect_back=" + window.location.href + "&queue_id=" + _this.uuid);
                }
            });
        });
    };
    QueueService.prototype.getUrlParameter = function (name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };
    ;
    QueueService.ngInjectableDef = i0.defineInjectable({ factory: function QueueService_Factory() { return new QueueService(i0.inject(i1.HttpClient), i0.inject(i0.INJECTOR)); }, token: QueueService, providedIn: "root" });
    return QueueService;
}());
export { QueueService };
