import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, NavigationEnd } from '@angular/router';

const DEFAULT_TRANSLATIONS = {
  global: {
    next: 'Next',
    back: 'Back',
    'toggle-info': 'toggle info',
    'toggle-group': 'toggle group',
    'error-group': 'invalid fields',
    overview: {
      'sub-total': 'Sub Total',
      'grand-total': 'Grand Total',
      discount: 'Discount',
      'service-fee': 'Service Fee',
      'payment-fee': 'Payment Fee'
    },
    forms: {
      male: 'Male',
      female: 'Female'
    }
  },
  header: {
    expires: 'Expires in {{time}}',
    expired: 'Order Expired',
    'expires-text': `You must complete an order within 15 minutes or your tickets won't be reserved.`,
    nav: {
      'step-1': 'Select Event',
      'step-2': 'Select Tickets',
      'step-3': 'Personal Details',
      'step-4': 'Review Order',
      'step-5': 'Complete Payment'
    }
  },
  footer: {
    copyright: 'Copyright &copy;'
  },
  coupon: {
    'not-found': 'Coupon "{{coupon}}" was not found.',
    'enter-code': 'Enter coupon code',
    submit: 'Submit'
  },
  tickets: {
    incl: 'incl.',
    excl: 'excl.',
    fee: 'fee',
    'add-to-cart': 'Add to Cart',
    'complete-your-order': 'Complete your Order',
    'ticket-i': 'Ticket #{{i}}',
    'not-available': 'These tickets are not available at this moment',
    'sold-out': 'These tickets are sold out'
  },
  details: {
    'ticket-details': 'Ticket Details',
    'order-details': 'Order Details',
    'product-details': 'Product Details'
  },
  review: {
    'coupon-codes': 'Coupon Codes',
    'select-payment-method': 'Select a payment method',
    'make-payment': 'Make Payment',
    'reserve-tickets': 'Reserve Tickets'
  },
  payment: {
    completed: {
      title: 'Your payment has been completed',
      description:
        'You will receive a link in your email inbox with your tickets in a few moments.'
    },
    failed: {
      title: 'Something Went Wrong',
      cancelled: 'Your payment was canceled, try again',
      failed: 'Your payment failed, try again',
      expired: 'Your payment expired, try again',
      back: 'Back To Payment Page'
    },
    processing: {
      title: 'Processing',
      description: 'We are processing your payment, please wait a moment.'
    },
    ticket: {
      qr: 'Open QR Code',
      download: 'Download Ticket',
      details: 'Details',
      code: 'Code',
      products: 'Products'
    }
  }
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService, private router: Router) {
    translate.setTranslation('en', DEFAULT_TRANSLATIONS);
    translate.setDefaultLang('en');
    translate.use('en');
  }

  ngOnInit() {
    this.router.events.subscribe((event: NavigationEnd) => {
      if (window) {
        window.scroll(0, 0);
      }
    });
  }
}
