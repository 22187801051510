import { Router } from "@angular/router";
import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";

import { tap } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { ActivatedRoute } from "@angular/router";
import { ShopService } from "@shared/services";
import { QueueService } from "@app/queue/queue.service";

@Injectable()
export class ShopInterceptor implements HttpInterceptor {
  constructor(
    private inj: Injector,
    private queueService: QueueService,
    private _router: Router // private toastService: ToastService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // const shop = this.inj.get(ShopService);
    // const authToken = auth.getAuthorizationHeader();
    // const authHeader = authToken;

    const headers = req.headers.set(
      "x-bitscan-queue-uuid",
      this.queueService.uuid
    );
    // .set('Authorization', authHeader)
    //   .set('Company', localStorage.getItem('bitscan_active_company'));
    const authReq = req.clone({
      headers: headers,
      url: req.url[0] === "/" ? environment.api + req.url : req.url
    });

    return next.handle(authReq).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // do stuff with response if you want
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
            } else if (err.status === 500) {
              console.error(
                "Whoops, something went wrong. Our devs have been notified. Try again, or contact support."
              );
            }
          }
        }
      )
    );
  }
}
