import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MatSnackBarModule } from '@angular/material';

import * as fromDirectives from './directives';
import * as fromPipes from './pipes';
import * as fromComponents from './components';
import { TranslateModule } from '@ngx-translate/core';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';

const MATERIAL_MODULES: any[] = [MatSnackBarModule];

@NgModule({
  declarations: [
    ...fromDirectives.directives,
    ...fromPipes.pipes,
    ...fromComponents.components,
    SanitizeHtmlPipe
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ...MATERIAL_MODULES,
    TranslateModule
  ],
  exports: [
    FlexLayoutModule,
    FormsModule,
    ...fromDirectives.directives,
    ...fromPipes.pipes,
    ...fromComponents.components,
    ...MATERIAL_MODULES,
    TranslateModule
  ]
})
export class SharedModule {}
