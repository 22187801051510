import { Injectable, Injector } from '@angular/core';
import { uuid } from 'uuidv4';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import {
  Route,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Router
} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  public uuid = '';

  constructor(
    private http: HttpClient,
    private injector: Injector // private activatedRoute: ActivatedRoute
  ) {}

  init(): Promise<any> {
    const idFromUrl = this.getUrlParameter('queue_id');
    if (idFromUrl !== null) {
      localStorage.setItem('bitscan_queue_uuid', idFromUrl);
    }

    return new Promise((resolve, reject) => {
      // return resolve();
      if (window.location.href.indexOf('bk-beats') === -1 || window.location.href.indexOf('payment') > -1) {
        return resolve();
      }
      if (localStorage.getItem('bitscan_queue_uuid')) {
        this.uuid = localStorage.getItem('bitscan_queue_uuid');
      } else {
        this.uuid = uuid();
        localStorage.setItem('bitscan_queue_uuid', this.uuid);
      }

      this.http
        .get(environment.queue_url + `/${environment.queue_key}/guest/${this.uuid}`, {
          // params: {
          //   key: environment.queue_key,
          //   url: window.location.href,
          //   id: this.uuid,
          // }
        })
        .subscribe((res: any) => {
          if (res.data.allowed_access) {
            return resolve();
          } else {
            const url = new URL(res.data.queue_url);
            const redirectUrl = new URL(window.location.href);
            redirectUrl.searchParams.append('queue_id', this.uuid);
            url.searchParams.append('redirect_back', redirectUrl.toString());
            console.log(url.toString());
            // console.log(res.data.queue_url + `?redirect_back=${window.location.href}&queue_id=${this.uuid}`);
            window.location.href = res.data.queue_url + `?redirect_back=${window.location.href}&queue_id=${this.uuid}`;
          }
        });
    });
  }

  getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        const results = regex.exec(location.search);
        return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };
}
